@import "../../../../assets/styles/common/variables";
@import "../../../../assets/styles/common/type-sizing";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.hg-links-grid {
  overflow: auto;
  display: flex;
  gap: 20px;
}

@include media-breakpoint-up(lg) {
  .hg-links-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-gap: 20px;
  }

  .hg-link-colour {
    grid-row: 8/13;
  }

  .hg-link-1 {
    grid-row: 1/8;
  }

  .hg-link-2, .hg-link-3 {
    grid-row: 1/7;
  }

  .hg-link-4, .hg-link-5 {
    grid-row: 7/13;
  }
}

@include media-breakpoint-up(xl) {
  .hg-link-colour {
    grid-row: 9/13;
  }

  .hg-link-1 {
    grid-row: 1/9;
  }
}

.hg-link {
  position: relative;
  background: $oslo-gray;
  min-width: 260px;

  @include media-breakpoint-up(md) {
    min-width: 300px;
  }

  @include media-breakpoint-up(lg) {
    min-width: auto;
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    aspect-ratio: 1/1.33;

    @include media-breakpoint-up(lg) {
      aspect-ratio: 1/1;
    }
  }

  &:not(.hg-link-colour)::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 35% at 50% 50%, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%),  rgba(0, 0, 0, 0.20);
    opacity: 0.5;
    z-index: 2;
    transition: 300ms;
  }

  &-text {
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2em;
    z-index: 3;
  }

  &:hover, &:focus {
    &::after {
      opacity: 1;
    }
  }
}